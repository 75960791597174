// Define all report type options
const MARKETPLACE_SELLER_LOOKUP_OPTION = {
  label: "Marketplace seller information",
  description:
    "Gather a list of marketplace sellers information (marketplace seller name and address)",
  dataTileInput: "Seller name, Tax ID (optional), Seller ID (optional)",
  dataTileOutput: "Name, CPF/CNPJ, Address",
  value: "BrazilMarketPlaceSellerLookup",
  disabled: false,
  isCritical: true,
};

const TRANSACTION_REPORT_OPTION = {
  label: "Transaction report",
  description: "Gather a list of seller transactions in Brazil",
  dataTileInput: "Legal Entity, Seller State, Tax ID",
  dataTileOutput:
    "3P seller information, buyer information, order information and invoice information",
  value: "TransactionReport",
  disabled: true,
  isCritical: false,
};

const CUSTOMER_LOOKUP_US_OPTION = {
  label: "Customer information lookup",
  description:
    "Gather a list of customer/buyer information (customer name and address)",
  dataTileInput: "Customer ID or Address ID",
  dataTileOutput: "Customer name, Address",
  value: "CustomerInformationLookupByBraavos",
  disabled: false,
  isCritical: true,
};

const THIRD_PARTY_SELLER_LOOKUP_OPTION = {
  label: "3P seller information",
  description:
    "Gather a list of 3P (third party) seller information (seller name and address)",
  dataTileInput: "Seller ID + Marketplace ID or Address ID",
  dataTileOutput: "3P seller name, Address",
  value: "3PSellerLookupReport",
  disabled: true,
  isCritical: true,
};

export const typeToReportName = (teamName: string, typeName: string) => {
  return (
    getReportTypesOnTeam(teamName).find((item) => item.value === typeName)
      ?.label ?? typeName
  );
};

// Define all report type options at team level on report selection page
const getReportTypesOnTeam = (teamName: string) => {
  switch (teamName) {
    //status for data query
    case "gaia_dev":
      return [
        MARKETPLACE_SELLER_LOOKUP_OPTION,
        TRANSACTION_REPORT_OPTION,
        CUSTOMER_LOOKUP_US_OPTION,
        THIRD_PARTY_SELLER_LOOKUP_OPTION,
      ];
    case "brazil_indirect":
      return [MARKETPLACE_SELLER_LOOKUP_OPTION, TRANSACTION_REPORT_OPTION];
    case "us_salt_indirect":
      return [CUSTOMER_LOOKUP_US_OPTION];
    default:
      return [];
  }
};

export const getDataReportTypes = (
  teamName: string,
  isCriticalUser: boolean
) => {
  const reportTypes = getReportTypesOnTeam(teamName);
  return isCriticalUser
    ? reportTypes
    : reportTypes.filter((reportType) => !reportType.isCritical);
};

// Define constants to for all customer lookup search type options
const CUSTOMER_INFO_LOOKUP_OPTION = {
  value: "customerInfo",
  label: "Customer information",
  description: "Customer name, ID, primary address, billing address",
  disabled: false,
};

const ADDRESS_INFO_LOOKUP_OPTION = {
  value: "addressInfo",
  label: "Address information",
  description: "Customer address details",
};

export const getCustomerInfoLookUpComponentTitle = (searchType: string) => {
  return searchType === "customerInfo"
    ? "Customer information"
    : "Address information";
};

export const getCustomerInfoSearchTypeLabel = (
  teamName: string,
  typeName: string
) => {
  return (
    getCustomerInfoSearchTypesOnTeam(teamName).find(
      (item) => item.value === typeName
    )?.label ?? typeName
  );
};

// Define customer lookup search types at team level
export const getCustomerInfoSearchTypesOnTeam = (teamName: string) => {
  switch (teamName) {
    case "gaia_dev":
      return [CUSTOMER_INFO_LOOKUP_OPTION, ADDRESS_INFO_LOOKUP_OPTION];
    case "brazil_indirect":
      return [];
    case "us_salt_indirect":
      return [CUSTOMER_INFO_LOOKUP_OPTION, ADDRESS_INFO_LOOKUP_OPTION];
    default:
      return [];
  }
};

export const convertUnixEpoch = (timeStamp: number) => {
  return new Date(timeStamp).toISOString().slice(0, 19);
};

// Customer information search type configuration based on team
export interface SearchIdOption {
  value: string;
  key: string;
  label: string;
  description: string;
}

const BUYER_ID_SEARCH_OPTION = {
  value: "buyerIds",
  key: "buyerIds",
  label: "Buyer ID",
};

const BUYER_EXEMPTION_CERT_ID_SEARCH_OPTION = {
  value: "buyerExemptionCertIds",
  key: "buyerExemptionCertIds",
  label: "Buyer exemption certification ID",
};

const ADDRESS_ID_SEARCH_OPTION = {
  value: "addressIds",
  key: "addressIds",
  label: "Address ID",
};

export interface SearchIdOptions {
  customerInfo: Array<SearchIdOption>;
  addressInfo: Array<SearchIdOption>;
}

export const getSearchIdOptionsOnTeam = (teamName: string) => {
  switch (teamName) {
    case "gaia_dev":
      return {
        customerInfo: [
          BUYER_ID_SEARCH_OPTION,
          BUYER_EXEMPTION_CERT_ID_SEARCH_OPTION,
        ],
        addressInfo: [ADDRESS_ID_SEARCH_OPTION],
      };
    case "brazil_indirect":
      return { customerInfo: [], addressInfo: [] };
    case "us_salt_indirect":
      return {
        customerInfo: [
          BUYER_ID_SEARCH_OPTION,
          BUYER_EXEMPTION_CERT_ID_SEARCH_OPTION,
        ],
        addressInfo: [ADDRESS_ID_SEARCH_OPTION],
      };
    default:
      return { customerInfo: [], addressInfo: [] };
  }
};

// Define search by ids options based on searchType
export const getSearchIdOptionsOnSearchType = (
  teamName: string,
  searchType: string
) => {
  return getSearchIdOptionsOnTeam(teamName)[
    searchType as keyof SearchIdOptions
  ].map((option) => ({
    value: option.value,
    label: option.label,
  }));
};

export const isAllowedOnQueryType = (
  teamName: string,
  isCriticalUser: boolean,
  queryType: string
) => {
  const res = getDataReportTypes(teamName, isCriticalUser);
  return res.some((item) => item.value === queryType);
};
